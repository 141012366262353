import React, { Component } from "react";
import "./aboutMe.styles.scss";

class AboutMe extends Component {
  render() {
    return (
      <div className="AboutMe" id="AboutMe">
        <h1>About Me</h1>
        <p className="AboutMePragraph">
          {/* Hi, My nickname is <span className="highlightPra">Zahin</span> 🖐🏽. Currently, I am doing My BSc in
            Computer Science & Engineering at AIUB 💻 📚. It's my Final Semester running 😬 🏃🏽‍♂️. 
            I live in <span className="highlightPra"> Dhaka, Bangladesh </span> 🏠. My Hometown is <span className="highlightPra">Kushtia, Bangladesh</span> 🏡.
            I like to travel ✈️🚂🚙, meeting with new People 🤝🏽, watching Movies/TV Series 📺, and Playing Games 🎮.
            I have some knowledge in <span className="highlightPra">Full-Stack Web </span> and
            <span className="highlightPra"> Mobile Application </span>Development 🤓.  */}
          <span
            style={{ fontSize: "25px", fontWeight: "bold", color: "#48566B" }}
          >
            Hello,
          </span>{" "}
          <br />
          My nickname is <span className="highlightPra">Zahin</span> 🖐🏽. It's
          been 3 years since I started working as a{" "}
          <span className="highlightPra">Software Engineer</span> 💼. Computer
          Studies are my passion and fashion ✨. I have been developing{" "}
          <span className="highlightPra">Web Applications</span> and{" "}
          <span className="highlightPra">Mobile Applications</span> for a long
          time ⌛. I look forward to every new opportunity with an open mind,
          with the goal of learning new things and improving each day 😄. Also,
          I like to <span className="highlightPra">travel</span> ✈️🚂🚙,{" "}
          <span className="highlightPra">meet with new People</span> 🤝🏽,
          <span className="highlightPra">watching Movies/TV Series</span> 📺,
          and <span className="highlightPra">playing Games</span>🕹️.
        </p>
      </div>
    );
  }
}

export default AboutMe;
