import React, { useEffect } from "react";
import "./skills.styles.scss";
import { Grid } from "@mui/material";
import { Tooltip, Button } from "@mui/material";
import NextJS from "../../icons/nextjs2.svg";
import { SiPostgresql } from "react-icons/si";
import { IoLogoJavascript, IoLogoSass } from "react-icons/io";
import { DiCss3, DiPhp } from "react-icons/di";
import { AiFillHtml5 } from "react-icons/ai";
import {
  FaAws,
  FaReact,
  FaPhp,
  FaPython,
  FaNodeJs,
  FaGitAlt,
  FaDocker,
} from "react-icons/fa";
import {
  SiRedis,
  SiFirebase,
  SiDotnet,
  SiJava,
  SiCsharp,
  SiExpress,
  SiJquery,
  SiMongodb,
  SiMysql,
  SiLaravel,
  SiCypress,
  SiJest,
  SiRedux,
  SiAdobephotoshop,
  SiAdobexd,
  SiMaterialui,
  SiNestjs,
} from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
import { CgCPlusPlus } from "react-icons/cg";
import { BsBootstrapFill } from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";

//https://react-icons.github.io/react-icons
const Skills = () => {
  const style = {
    fontSize: "50px",
    color: "#48566B",
  };

  useEffect(() => {
    Aos.init({ duration: 10500 });
  }, []);

  return (
    <div className="Skills" id="skills">
      <br />
      <h1>Skills</h1>
      <br />
      {/* <ul>
            <li>HTML/HTML5 <IoLogoJavascript style={{fontSize:'50px',color:'white'}}/></li>
            <li>CSS/CSS3</li>
            <li>JavaScript</li>
            <li>C#</li>
            <li>ASP.Net</li>
            <li>React.js</li>
            <li>Laravel</li>
            <li>React Native</li>
            <li>Redux</li>
            <li>Google FireBase</li>
            <li>RESTful API</li>
            <li>Mysql</li>
            <li>Ajax</li>
            <li>PHP</li>
            <li>C/C++</li>
            <li>JAVA</li>
            <li>JAVA Swing</li>
            <li>Bootstrap</li>
            <li>Python</li>
            <li>Assembly language</li>
            <li>MatLAB</li>
            <li>Photoshop</li>
        </ul> */}

      <div className="Datas">
        <Tooltip title="Javascript" placement="top">
          <Button>
            <IoLogoJavascript style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="CSS" placement="top">
          <Button>
            <DiCss3 style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="HTML" placement="top">
          <Button>
            <AiFillHtml5 style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="JAVA" placement="top">
          <Button>
            <SiJava style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Python" placement="top">
          <Button>
            <FaPython style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="C#" placement="top">
          <Button>
            <SiCsharp style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="C++" placement="top">
          <Button>
            <CgCPlusPlus style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="PHP" placement="top">
          <Button>
            <FaPhp style={style} />{" "}
          </Button>
        </Tooltip>
      </div>
      {/* <div className="DatasBottomLabel">
                Languages
        </div> */}

      {/* ----------------------------------------------------------- */}

      <div className="Datas">
        <Tooltip title="React and React Native" placement="top">
          <Button>
            <FaReact style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="NodeJs" placement="top">
          <Button>
            <FaNodeJs style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Express JS" placement="top">
          <Button>
            <SiExpress style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Nest JS" placement="top">
          <Button>
            <SiNestjs style={style} />
          </Button>
        </Tooltip>
        <Tooltip title="ASP.Net" placement="top">
          <Button>
            <SiDotnet style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Jquery" placement="top">
          <Button>
            <SiJquery style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Next JS" placement="top">
          <Button>
            <TbBrandNextjs style={style} />
          </Button>
        </Tooltip>
        <Tooltip title="Mongodb" placement="top">
          <Button>
            <SiMongodb style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Google Firebase" placement="top">
          <Button>
            <SiFirebase style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Mysql" placement="top">
          <Button>
            <SiMysql style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Postgresql" placement="top">
          <Button>
            <SiPostgresql style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Laravel" placement="top">
          <Button>
            <SiLaravel style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Sass" placement="top">
          <Button>
            <IoLogoSass style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Bootstrap" placement="top">
          <Button>
            <BsBootstrapFill style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Material UI" placement="top">
          <Button>
            <SiMaterialui style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Cypress" placement="top">
          <Button>
            <SiCypress style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Jest" placement="top">
          <Button>
            <SiJest style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Redux" placement="top">
          <Button>
            <SiRedux style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Docker" placement="top">
          <Button>
            <FaDocker style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="AWS Cloud" placement="top">
          <Button>
            <FaAws style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Redis" placement="top">
          <Button>
            <SiRedis style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="Git" placement="top">
          <Button>
            <FaGitAlt style={style} />{" "}
          </Button>
        </Tooltip>
      </div>
      {/* <div className="DatasBottomLabel">
                Technology
        </div> */}

      {/* ----------------------------------------------------------- */}
      <div className="Datas">
        <Tooltip title="Photoshop" placement="top">
          <Button>
            <SiAdobephotoshop style={style} />{" "}
          </Button>
        </Tooltip>
        <Tooltip title="AdobeXD" placement="top">
          <Button>
            <SiAdobexd style={style} />{" "}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Skills;
